var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.internalValue
      ? _c(
          "div",
          { staticClass: "elementPanel elementPanel--spaced fill-height" },
          [
            _c(
              "div",
              { staticClass: "tw-flex tw-justify-between" },
              [
                _c("h2", { staticClass: "tw-mb-16" }, [
                  _vm._v("Algemene uurtarieven")
                ]),
                !_vm.isAutoSave
                  ? _c(
                      "AButton",
                      {
                        staticClass: "tw-self-center",
                        class: _vm.hasChanges
                          ? "tw-bg-secondary-400 tw-text-white"
                          : "tw-border-gray-500 tw-text-gray-500 tw-bg-white",
                        attrs: {
                          "prepend-inner-icon": "save",
                          loading: _vm.isUpdating,
                          disabled: !_vm.hasChanges
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onSave()
                          }
                        }
                      },
                      [_vm._v(" Opslaan ")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("GeneralTariffs", {
              attrs: { isAutoSave: _vm.isAutoSave },
              on: {
                input: function($event) {
                  return _vm.onInput($event)
                },
                inputBlur: _vm.onInputBlur
              },
              model: {
                value: _vm.internalValue.general_levels,
                callback: function($$v) {
                  _vm.$set(_vm.internalValue, "general_levels", $$v)
                },
                expression: "internalValue.general_levels"
              }
            }),
            !_vm.$store.state.isServiceOrganization
              ? [
                  _c("h2", [_vm._v("Aangepast uurtarieven per rapport type")]),
                  _c(
                    "v-tabs",
                    {
                      attrs: { "slider-color": "secondary" },
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          class: {
                            active: _vm.activeTab === "tab-hourly_rate"
                          },
                          attrs: { href: "#tab-hourly_rate" }
                        },
                        [_vm._v("Verkooptarief")]
                      ),
                      !_vm.$store.state.isServiceOrganization
                        ? _c(
                            "v-tab",
                            {
                              class: {
                                active: _vm.activeTab === "tab-internal_rate"
                              },
                              attrs: { href: "#tab-internal_rate" }
                            },
                            [_vm._v(" Inkooptarief ")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            transition: false,
                            "reverse-transition": false,
                            lazy: true,
                            value: "tab-hourly_rate"
                          }
                        },
                        [
                          _c("HourlyTariffs", {
                            attrs: {
                              type: "hourly_rate",
                              labels: _vm.hourLevelLabels,
                              isAutoSave: _vm.isAutoSave
                            },
                            on: {
                              input: function($event) {
                                return _vm.onInput($event)
                              },
                              inputBlur: _vm.onInputBlur
                            },
                            model: {
                              value: _vm.internalValue.report_types,
                              callback: function($$v) {
                                _vm.$set(_vm.internalValue, "report_types", $$v)
                              },
                              expression: "internalValue.report_types"
                            }
                          })
                        ],
                        1
                      ),
                      !_vm.$store.state.isServiceOrganization
                        ? _c(
                            "v-tab-item",
                            {
                              attrs: {
                                transition: false,
                                "reverse-transition": false,
                                lazy: true,
                                value: "tab-internal_rate"
                              }
                            },
                            [
                              _c("HourlyTariffs", {
                                attrs: {
                                  type: "internal_rate",
                                  labels: _vm.hourLevelLabels,
                                  isAutoSave: _vm.isAutoSave
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onInput($event)
                                  },
                                  inputBlur: _vm.onInputBlur
                                },
                                model: {
                                  value: _vm.internalValue.report_types,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.internalValue,
                                      "report_types",
                                      $$v
                                    )
                                  },
                                  expression: "internalValue.report_types"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }