var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        {
          staticClass: "item__list mb-5",
          attrs: { wrap: "", "justify-end": "" }
        },
        [
          _c(
            "v-flex",
            { staticClass: "label", attrs: { x12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs2: "" } }, [_vm._v("Type")]),
                  _c("v-flex", { attrs: { xs5: "" } }, [
                    _vm._v("Verkoop tarief")
                  ]),
                  !_vm.$store.state.isServiceOrganization
                    ? _c("v-flex", { attrs: { xs5: "" } }, [
                        _vm._v("Inkoop tarief")
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.internalValue, function(entry, index) {
            return _c("GeneralTariffRow", {
              key: "hour_level" + entry[0],
              attrs: {
                rowLabel: _vm.urenadministratieLevelLabels[entry[0]],
                inputLabel: _vm.urenadministratieLevelLabels[entry[0]],
                isAutoSave: _vm.isAutoSave
              },
              on: {
                input: function($event) {
                  return _vm.onInput($event)
                },
                inputBlur: function($event) {
                  return _vm.onInputBlur($event)
                }
              },
              model: {
                value: _vm.internalValue[index][1],
                callback: function($$v) {
                  _vm.$set(_vm.internalValue[index], 1, $$v)
                },
                expression: "internalValue[index][1]"
              }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }