import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { userUrenadministratieLevelLabels } from '@/models/User';
import { TariffMatrixReportTypeEntry, TariffType } from '../HourTariffMatrix';
import HourlyTariffRow from './HourlyTariffRow/HourlyTariffRow.vue';

@Component<HourlyTariffs>({
  components: {
    HourlyTariffRow,
  },
})
export default class HourlyTariffs extends Vue {
  @VModel()
  protected internalValue!: TariffMatrixReportTypeEntry[];

  @Prop()
  protected labels!: Record<string, string>;

  @Prop({ required: true })
  protected type!: TariffType;

  @Prop({ default: true })
  protected isAutoSave!: boolean;

  protected urenadministratieLevelLabels = userUrenadministratieLevelLabels;

  protected onInputBlur(): void {
    this.$emit('inputBlur');
  }

  protected onInput() {
    this.$emit('input', this.internalValue);
  }
}
