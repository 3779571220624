var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "list__item", attrs: { xs12: "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "align-baseline": "" } },
        [
          _c("v-flex", { attrs: { xs2: "" } }, [_vm._v(_vm._s(_vm.rowLabel))]),
          _c(
            "v-flex",
            { attrs: { xs5: "" } },
            [
              _c("HourPriceInput", {
                attrs: {
                  initialValue: _vm.internalValue.hourly_rate_initial,
                  label: _vm.inputLabel,
                  isAutoSave: _vm.isAutoSave
                },
                on: {
                  input: function($event) {
                    return _vm.onInput($event)
                  },
                  inputBlur: function($event) {
                    return _vm.onInputBlur($event)
                  }
                },
                model: {
                  value: _vm.internalValue.hourly_rate,
                  callback: function($$v) {
                    _vm.$set(_vm.internalValue, "hourly_rate", $$v)
                  },
                  expression: "internalValue.hourly_rate"
                }
              })
            ],
            1
          ),
          !_vm.$store.state.isServiceOrganization
            ? _c(
                "v-flex",
                { attrs: { xs5: "" } },
                [
                  _c("HourPriceInput", {
                    attrs: {
                      initialValue: _vm.internalValue.internal_rate_initial,
                      label: _vm.inputLabel,
                      isAutoSave: _vm.isAutoSave
                    },
                    on: {
                      input: function($event) {
                        return _vm.onInput($event)
                      },
                      inputBlur: function($event) {
                        return _vm.onInputBlur($event)
                      }
                    },
                    model: {
                      value: _vm.internalValue.internal_rate,
                      callback: function($$v) {
                        _vm.$set(_vm.internalValue, "internal_rate", $$v)
                      },
                      expression: "internalValue.internal_rate"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }