import HourPriceInputComponent from '@/components/HourTariffMatrix/HourPriceInput/HourPriceInput.vue';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { TariffMatrixRow } from '../../HourTariffMatrix';

@Component<GeneralTariffRow>({
  components: {
    HourPriceInput: HourPriceInputComponent,
  },
})
export default class GeneralTariffRow extends Vue {
  @VModel()
  protected internalValue!: TariffMatrixRow | null;

  @Prop({ default: '' })
  protected rowLabel!: string;

  @Prop({ default: '' })
  protected inputLabel!: string;

  @Prop({ default: true })
  protected isAutoSave!: boolean;

  protected onInputBlur(): void {
    this.$emit('inputBlur');
  }

  protected onInput(event: any) {
    this.$emit('input', this.internalValue);
  }
}
