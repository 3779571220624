import { Component, Vue, Prop, VModel } from 'vue-property-decorator';

@Component<HourPriceInput>({})
export default class HourPriceInput extends Vue {
  @Prop({ required: true })
  protected label!: string;

  @Prop({ default: true })
  protected isAutoSave!: boolean;

  @Prop()
  protected initialValue!: number;

  @VModel()
  protected internalValue!: number;

  protected onBlur(): void {
    this.$emit('inputBlur');
  }

  public get hasChanged(): boolean {
    return this.initialValue !== this.internalValue;
  }
}
