import HourPriceInput from '@/components/HourTariffMatrix/HourPriceInput/HourPriceInput.vue';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { TariffMatrixReportTypeEntry, TariffType } from '../../HourTariffMatrix';

@Component<HourlyTariffRow>({
  components: {
    HourPriceInput,
  },
})
export default class HourlyTariffRow extends Vue {
  @VModel()
  protected internalValue!: TariffMatrixReportTypeEntry | null;

  @Prop({ required: true })
  protected type!: TariffType;

  @Prop({ default: '' })
  protected rowLabel!: string;

  @Prop({ default: '' })
  protected inputLabel!: string;

  @Prop({ default: true })
  protected isAutoSave!: boolean;

  protected onInputBlur(): void {
    this.$emit('inputBlur');
  }

  protected onInput() {
    this.$emit('input', this.internalValue);
  }
}
