var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { staticClass: "item__list", attrs: { wrap: "", "justify-end": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "label", attrs: { x12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs2: "" } }, [_vm._v("Type")]),
                  _vm._l(_vm.labels, function(label) {
                    return _c("v-flex", { key: "hourlevel" + label }, [
                      _vm._v(" " + _vm._s(label) + " ")
                    ])
                  })
                ],
                2
              )
            ],
            1
          ),
          _vm._l(_vm.internalValue, function(entry, index) {
            return _c("HourlyTariffRow", {
              key: "report_types" + index,
              attrs: {
                rowLabel: entry.name,
                type: _vm.type,
                isAutoSave: _vm.isAutoSave
              },
              on: {
                input: function($event) {
                  return _vm.onInput($event)
                },
                inputBlur: _vm.onInputBlur
              },
              model: {
                value: _vm.internalValue[index],
                callback: function($$v) {
                  _vm.$set(_vm.internalValue, index, $$v)
                },
                expression: "internalValue[index]"
              }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }