import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { Level, UrenadministratieLevel, userUrenadministratieLevelLabels } from '@/models/User';
import HourlyTariffs from '@/components/HourTariffMatrix/HourlyTariffs/HourlyTariffs.vue';
import _, { debounce, DebouncedFunc } from 'lodash';
import { Rpc } from '@/models/Rpc';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import GeneralTariffs from './GeneralTariffs/GeneralTariffs.vue';

@Component<HourTariffMatrix>({
  components: {
    GeneralTariffs,
    HourlyTariffs,
  },
})
export default class HourTariffMatrix extends Vue {
  @VModel()
  protected internalValue!: ParsedTariffMatrix;

  @Prop({ default: true })
  protected isAutoSave!: boolean;

  @Prop()
  protected organizationId!: string;

  @Prop()
  protected userId!: string;

  protected userUrenadministratieLevelLabels = userUrenadministratieLevelLabels;

  protected activeTab = 'tab-hourly_rate';

  protected hasChanges = false;

  protected isUpdating = false;

  protected payload: TariffUpdateMatrixPayload[] = [];

  protected debounceSave: () => void = debounce(this.save, 400);

  protected async onSave(): Promise<void> {
    this.isUpdating = true;
    await this.updateMatrix();
    this.isUpdating = false;
  }

  protected async save(): Promise<void> {
    this.isUpdating = true;
    this.getPayload();
    await this.updateMatrix();
    this.isUpdating = false;
  }

  protected onInput(event: any) {
    if (this.isAutoSave) {
      return;
    }

    this.setHasChanges();

    if (this.hasChanges) {
      this.getPayload();
    }
  }

  protected onInputBlur(event: any) {
    if (! this.isAutoSave) {
      return;
    }
    this.getPayload();
    if (this.payload.length > 0) {
      this.debounceSave();
    }
  }

  protected setHasChanges(): void {
    this.hasChanges = this.internalValue.general_levels.some((level) => {
      const row = level[1];

      return (row.hourly_rate !== row.hourly_rate_initial) || (row.internal_rate !== row.internal_rate_initial);
    })
    || this.internalValue.report_types.some((entry) => entry.levels.some((level) => {
      const row = level[1];

      return (row.hourly_rate !== row.hourly_rate_initial) || (row.internal_rate !== row.internal_rate_initial);
    }));
  }

  protected getPayload(): void {
    const changedGeneralLevelPayloads: TariffUpdateMatrixPayload[] = [];
    const changedReportTypePayloads: TariffUpdateMatrixPayload[] = [];

    this.internalValue.general_levels.forEach((level) => {
      if (level[1].hourly_rate !== level[1].hourly_rate_initial) {
        changedGeneralLevelPayloads.push({
          level: level[0],
          hourly_rate: level[1].hourly_rate,
          user_id: this.userId ? this.userId : '',
        });
      }

      if (level[1].internal_rate !== level[1].internal_rate_initial) {
        changedGeneralLevelPayloads.push({
          level: level[0],
          internal_rate: level[1].internal_rate,
          user_id: this.userId ? this.userId : '',
        });
      }
    });

    this.internalValue.report_types.forEach((entry) => entry.levels.forEach((level) => {
      if (level[1].hourly_rate !== level[1].hourly_rate_initial) {
        changedReportTypePayloads.push({
          report_type_id: entry.id,
          level: level[0],
          hourly_rate: level[1].hourly_rate,
          user_id: this.userId ? this.userId : '',
        });
      }

      if (level[1].internal_rate !== level[1].internal_rate_initial) {
        changedReportTypePayloads.push({
          report_type_id: entry.id,
          level: level[0],
          internal_rate: level[1].internal_rate,
          user_id: this.userId ? this.userId : '',
        });
      }
    }));

    this.payload = [...changedGeneralLevelPayloads, ...changedReportTypePayloads];
  }

  protected async updateMatrix(): Promise<void> {
    try {
      await new Rpc()
        .dmz(this.organizationId ? this.organizationId : '')
        .rpcPost({
          signature: 'tariffs:update-matrix',
          body: {
            tariffs: this.payload,
          },
        }, false);
      this.$emit('updatedMatrix');
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected get hourLevelLabels(): Record<string, string> {
    const hourLevels: Record<string, string> = {};
    Object.values(this.internalValue.general_levels as any[]).forEach((level) => {
      hourLevels[level[0]] = userUrenadministratieLevelLabels[level[0]];
    });
    return hourLevels;
  }
}

export const parseMatrix = (matrix: TariffMatrix): ParsedTariffMatrix => {
  const parsedMatrix: ParsedTariffMatrix = {
    general_levels: [],
    report_types: [],
  };

  parsedMatrix.general_levels = (Object.entries(matrix.general_levels) as [string, Partial<ParsedTariffMatrixRow>][])
    .map((level) => {
      if (level[1]) {
        return level;
      }

      level[1] = { internal_rate: null, hourly_rate: null };

      return level as [string, Partial<ParsedTariffMatrixRow>];
    })
    .map((level) => {
      level[1] = { ...level[1], internal_rate_initial: level[1]?.internal_rate, hourly_rate_initial: level[1]?.hourly_rate };

      return level;
    });

  parsedMatrix.report_types = matrix.report_types.map((type: TariffMatrixReportTypeEntry) => {
    const levels = (Object.entries(type.levels) as [string, Partial<ParsedTariffMatrixRow> | null][])
      .map((level) => {
        if (! level[1]) {
          level[1] = { internal_rate: null, hourly_rate: null };
        }
        return level as [string, Partial<ParsedTariffMatrixRow>];
      })
      .map((level) => {
        level[1] = { ...level[1], internal_rate_initial: level[1]?.internal_rate, hourly_rate_initial: level[1]?.hourly_rate };

        return level;
      });

    return {
      id: type.id,
      name: type.name,
      levels,
    } as ParsedTariffMatrixReportTypeEntry;
  });

  return parsedMatrix;
};

export type TariffMatrixLevel = {
  [key in UrenadministratieLevel]?: TariffMatrixRow | null;
};

export type ParsedTariffMatrixLevel = [string, ParsedTariffMatrixRow];

export interface TariffMatrixReportTypeEntry {
  id: string;
  name: string;
  levels: TariffMatrixLevel;
}

export interface ParsedTariffMatrixReportTypeEntry {
  id: string;
  name: string;
  levels: ParsedTariffMatrixLevel[];
}

export interface TariffMatrix {
  general_levels: TariffMatrixLevel;
  report_types: TariffMatrixReportTypeEntry[];
}

export interface ParsedTariffMatrix {
  general_levels: ParsedTariffMatrixLevel[];
  report_types: ParsedTariffMatrixReportTypeEntry[];
}

export interface TariffMatrixRow {
  hourly_rate?: number | null,
  internal_rate?: number | null,
}

export interface ParsedTariffMatrixRow extends TariffMatrixRow {
  hourly_rate_initial?: number | null,
  internal_rate_initial?: number | null,
}

export interface TariffUpdateMatrixPayload {
  level: string,
  user_id?: string | null,
  report_type_id?: string,
  hourly_rate?: number | null,
  internal_rate?: number | null,
}
export type TariffType = 'internal_rate' | 'hourly_rate';
