import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { userUrenadministratieLevelLabels } from '@/models/User';
import { TariffMatrixLevel } from '../HourTariffMatrix';
import GeneralTariffRow from './GeneralTariffRow/GeneralTariffRow.vue';

@Component<GeneralTariffs>({
  components: {
    GeneralTariffRow,
  },
})
export default class GeneralTariffs extends Vue {
  @VModel()
  protected internalValue!: TariffMatrixLevel[];

  @Prop({ default: true })
  protected isAutoSave!: boolean;

  protected urenadministratieLevelLabels = userUrenadministratieLevelLabels;

  protected onInputBlur(): void {
    this.$emit('inputBlur');
  }

  protected onInput(event: any) {
    this.$emit('input', this.internalValue);
  }
}
