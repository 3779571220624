var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", [_vm._v("€")]),
      _c(
        "v-text-field",
        _vm._b(
          {
            staticClass: "price__field",
            class: { changed: !_vm.isAutoSave && _vm.hasChanged },
            attrs: {
              placeholder: "-",
              "hide-details": "",
              type: "number",
              label: _vm.label
            },
            on: { blur: _vm.onBlur },
            model: {
              value: _vm.internalValue,
              callback: function($$v) {
                _vm.internalValue = $$v
              },
              expression: "internalValue"
            }
          },
          "v-text-field",
          _vm.$attrs,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }