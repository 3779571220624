var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "list__item", attrs: { xs12: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "align-baseline": "" } },
        [
          _c("v-flex", { staticClass: "report-type", attrs: { xs2: "" } }, [
            _vm._v(_vm._s(_vm.rowLabel))
          ]),
          _vm._l(_vm.internalValue.levels, function(
            tariffLevelEntry,
            levelKey
          ) {
            return _c(
              "v-flex",
              { key: "tariff_level_" + levelKey },
              [
                _c("HourPriceInput", {
                  attrs: {
                    label: _vm.inputLabel,
                    initialValue: tariffLevelEntry[1][_vm.type + "_initial"],
                    isAutoSave: _vm.isAutoSave
                  },
                  on: {
                    input: function($event) {
                      return _vm.onInput($event)
                    },
                    inputBlur: _vm.onInputBlur
                  },
                  model: {
                    value: tariffLevelEntry[1][_vm.type],
                    callback: function($$v) {
                      _vm.$set(tariffLevelEntry[1], _vm.type, $$v)
                    },
                    expression: "tariffLevelEntry[1][type]"
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }